<template>
  <div>
    <transition name="fade">
      <div
        v-if="positionMobileDropdownToBottom && isOpen"
        class="fixed inset-0 z-20 flex w-full bg-black/25 md:hidden"
      />
    </transition>
    <v-select
      ref="vSelectRef"
      v-bind="$attrs"
      :style="cssVariables"
      :append-to-body="appendToBody"
      :calculate-position="calculatePosition"
      transition=""
      @open="isOpen = true"
      @option:selected="isOpen = false"
      @close="isOpen = false"
    >
      <template #list-header>
        <li v-if="title" class="flex justify-between border-b p-3 md:hidden">
          <span>{{ title }}</span>
          <button @click="blur">
            <DynamicPictogram name="times" size="sm" />
          </button>
        </li>
      </template>
      <template #option="option">
        <slot name="option" :option="option"></slot>
      </template>
      <template #selected-option="option">
        <slot name="selected-option" v-bind="option"></slot>
      </template>
      <template
        #selected-option-container="{ option, deselect, multiple, disabled }"
      >
        <slot
          name="selected-option-container"
          v-bind="{ option, deselect, multiple, disabled }"
        ></slot>
      </template>
    </v-select>
  </div>
</template>

<script setup lang="ts">
import vSelect from 'vue-select';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  inputBgColor: {
    type: String,
    default: 'white',
  },
  inputBorderRadius: {
    type: String,
    default: '4px',
  },
  inputPlaceholderColor: {
    type: String,
    default: 'rgba(black, 0.87)',
  },
  positionMobileDropdownToBottom: {
    type: Boolean,
    default: false,
  },
  appendToBody: {
    type: Boolean,
    default: true,
  },
});
const isOpen = ref(false);
const vSelectRef: Ref<{ $el?: HTMLElement } | null> = ref(null);

interface DropdownPosition {
  top: string;
  left: string;
  width: string;
}

const cssVariables = computed(() => ({
  '--vs-border-radius': props.inputBorderRadius,
  '--input-bg-color': props.inputBgColor,
  '--input-placeholder-color': props.inputPlaceholderColor,
}));

const blur = () => {
  const inputEl =
    vSelectRef?.value?.$el?.getElementsByClassName('vs__search')?.[0] ?? null;
  if (inputEl instanceof HTMLElement) {
    inputEl.blur();
  }
};

const calculatePosition = (
  dropdownList: HTMLElement,
  _: any,
  { top, left, width }: DropdownPosition
) => {
  /* eslint-disable no-param-reassign */

  if (
    props.positionMobileDropdownToBottom &&
    process.client &&
    window.innerWidth < 768
  ) {
    dropdownList.style.position = 'fixed';
    dropdownList.style.top = 'initial';
    dropdownList.style.bottom = '0px';
    dropdownList.style.left = '0px';
    dropdownList.style.width = '100%';
  } else {
    const offsetHeight = vSelectRef.value?.$el?.offsetHeight || 0;
    dropdownList.style.top = `${parseInt(top.replace('px', ''), 10) - offsetHeight}px`;
    dropdownList.style.left = left;
    dropdownList.style.width = width;
  }
  /* eslint-enable no-param-reassign */
};
</script>

<style lang="postcss">
:root {
  --vs-search-input-placeholder-color: black;
  --vs-selected-bg: rgba(0, 0, 0, 0.06);
  --vs-dropdown-option--active-bg: rgba(0, 0, 0, 0.06);
  --vs-dropdown-option--active-color: black;
  --vs-dropdown-option-padding: 8px 16px;
  --vs-border-color: rgba(0, 0, 0, 0.26);
  --vs-actions-padding: 0;
  --vs-disabled-bg: white;
}

.vs__dropdown-menu {
  border-top-style: solid;
  border-radius: 4px;
}
</style>

<style lang="scss" scoped>
:deep(.vs__dropdown-toggle) {
  @apply p-3;
}

:deep(.vs--single.vs--open .vs__selected) {
  @apply opacity-100 static;
}

:deep(.v-select) {
  background: var(--input-bg-color);

  &.vs--disabled {
    @apply opacity-50;
  }
}

:deep(.vs__search),
:deep(.vs__selected) {
  margin: 0;
  padding: 0;
}

:deep(.vs__search::placeholder) {
  color: var(--input-placeholder-color);
}

:deep(.vs__search:disabled) {
  border: none;
}

@media (max-width: theme('screens.sm')) {
  :deep(.vs__dropdown-toggle) {
    background: var(--input-bg-color);
    border-bottom-color: rgba(0, 0, 0, 0.26);
  }
}
</style>
